import { useForm, ValidationError } from "@formspree/react";
import React, { useState } from "react";
import {
  ButtonToolbar,
  Col,
  InputGroup,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

function SocietyContactForm({ buttonText }) {
  const [selectedButton, setSelectedButton] = useState("AM");

  const handleButtonClick = (value) => {
    setSelectedButton(value);
  };
  const [state, handleSubmit] = useForm("myyqrele");
  if (state.succeeded) {
    return (
      <>
        {" "}
        <Form className="w-100 py-5 text-center" onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Control
              className="0 py-3 pb-xl-5 fs-5"
              name="name"
              placeholder="Name"
              type="text"
            />
          </Form.Group>
          <ValidationError prefix="Name" field="name" errors={state.errors} />
          <Form.Group className="mb-3" controlId="numberOfPlayers">
            <Form.Control
              className="0 py-3 pb-xl-5 fs-5"
              name="Number of players"
              placeholder="Number of players"
              type="number"
            />
          </Form.Group>
          <ValidationError
            prefix="numberOfPlayers"
            field="numberOfPlayers"
            errors={state.errors}
          />

          <Form.Group className="mb-3" controlId="socName">
            <Form.Control
              className=" py-3 pb-xl-5 fs-5"
              name="socName"
              placeholder="Society Name"
              type="text"
            />
          </Form.Group>
          <ValidationError
            prefix="Society Name"
            field="socName"
            errors={state.errors}
          />

          <Form.Group className="mb-3" controlId="phone">
            <Form.Control
              className=" py-3 pb-xl-5 fs-5"
              name="phone"
              placeholder="Phone"
              type="tel"
            />
          </Form.Group>
          <ValidationError prefix="Phone" field="phone" errors={state.errors} />

          <Form.Group className="mb-3" controlId="email">
            <Form.Control
              className=" py-3 pb-xl-5 fs-5"
              name="_replyto"
              type="email"
              placeholder="Email"
            />
          </Form.Group>
          <ValidationError prefix="Email" field="email" errors={state.errors} />

          <Form.Group className="mb-3" controlId="date">
            <Form.Control
              className=" py-3 pb-xl-5 fs-5"
              name="date"
              type="text"
              placeholder="Date"
            />
          </Form.Group>
          <ValidationError prefix="Date" field="date" errors={state.errors} />

          {/* <Form.Group className="mb-3" controlId="time">
        <Form.Control
          className=" py-3 pb-xl-5 fs-5"
          name="time"
          type="text"
          value={selectedButton}
        />
      </Form.Group> */}

          <div className="d-flex align-items-center justify-content-between">
            <label htmlFor="time" className="ps-3 fs-5 text-start">
              Preferred tee time
            </label>
            <ToggleButtonGroup
              id="time"
              type="radio"
              name="time"
              value={selectedButton}
            >
              <ToggleButton
                variant="outline-primary"
                value="AM"
                onClick={() => handleButtonClick("AM")}
                className="py-3 px-5"
              >
                AM
              </ToggleButton>
              <ToggleButton
                variant="outline-primary"
                value="PM"
                onClick={() => handleButtonClick("PM")}
                className="py-3 px-5"
              >
                PM
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <ValidationError prefix="Time" field="time" errors={state.errors} />

          {/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
          <div className="text-center">
            <Button
              variant="primary"
              className="px-5 mt-4 text-uppercase text-white me-md-3 py-3 w-100 "
              type="submit"
              id="contact-send-btn"
              disabled={state.submitting}
            >
              {buttonText ?? "Submit"}
            </Button>
          </div>
        </Form>
        <div className="d-none">{(window.location = "/thank-you")}</div>
      </>
    );
  }
  return (
    <Form className="w-100 py-5 text-center" onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="name">
        <Form.Control
          className="0 py-3 pb-xl-5 fs-5"
          name="name"
          placeholder="Name"
          type="text"
        />
      </Form.Group>
      <ValidationError prefix="Name" field="name" errors={state.errors} />
      <Form.Group className="mb-3" controlId="numberOfPlayers">
        <Form.Control
          className="0 py-3 pb-xl-5 fs-5"
          name="Number of players"
          placeholder="Number of players"
          type="number"
        />
      </Form.Group>
      <ValidationError
        prefix="numberOfPlayers"
        field="numberOfPlayers"
        errors={state.errors}
      />

      <Form.Group className="mb-3" controlId="socName">
        <Form.Control
          className=" py-3 pb-xl-5 fs-5"
          name="socName"
          placeholder="Society Name"
          type="text"
        />
      </Form.Group>
      <ValidationError
        prefix="Society Name"
        field="socName"
        errors={state.errors}
      />

      <Form.Group className="mb-3" controlId="phone">
        <Form.Control
          className=" py-3 pb-xl-5 fs-5"
          name="phone"
          placeholder="Phone"
          type="tel"
        />
      </Form.Group>
      <ValidationError prefix="Phone" field="phone" errors={state.errors} />

      <Form.Group className="mb-3" controlId="email">
        <Form.Control
          className=" py-3 pb-xl-5 fs-5"
          name="_replyto"
          type="email"
          placeholder="Email"
        />
      </Form.Group>
      <ValidationError prefix="Email" field="email" errors={state.errors} />

      <Form.Group className="mb-3" controlId="date">
        <Form.Control
          className=" py-3 pb-xl-5 fs-5"
          name="date"
          type="text"
          placeholder="Date"
        />
      </Form.Group>
      <ValidationError prefix="Date" field="date" errors={state.errors} />

      {/* <Form.Group className="mb-3" controlId="time">
        <Form.Control
          className=" py-3 pb-xl-5 fs-5"
          name="time"
          type="text"
          value={selectedButton}
        />
      </Form.Group> */}

      <div className="d-flex align-items-center justify-content-between">
        <label htmlFor="time" className="ps-3 fs-5 text-start">
          Preferred tee time
        </label>
        <ToggleButtonGroup
          id="time"
          type="radio"
          name="time"
          value={selectedButton}
        >
          <ToggleButton
            variant="outline-primary"
            value="AM"
            onClick={() => handleButtonClick("AM")}
            className="py-3 px-5"
          >
            AM
          </ToggleButton>
          <ToggleButton
            variant="outline-primary"
            value="PM"
            onClick={() => handleButtonClick("PM")}
            className="py-3 px-5"
          >
            PM
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <ValidationError prefix="Time" field="time" errors={state.errors} />

      {/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
      <div className="text-center">
        <Button
          variant="primary"
          className="px-5 mt-4 text-uppercase text-white me-md-3 py-3 w-100 "
          type="submit"
          id="contact-send-btn"
          disabled={state.submitting}
        >
          Submit
        </Button>
      </div>
    </Form>
  );
}
export default SocietyContactForm;
